import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/layout";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PropertiesView from "./pages/Properties";
import PropertyDetails from "./pages/Property_Details";

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/properties" element={<PropertiesView/>} />
          <Route path="/property_details/:property_name" element={<PropertyDetails/>} />
          {/* <Route path="/contact" component={Contact} /> */}
          {/* Add more routes as needed */}
        </Routes>
     
    </Router>
  );
}

export default App;
